import { Breadcrumb, BreadcrumbItem } from '@/components/Breadcrumb'
import { SEO } from '@/components/SEO'
import { PortfolioLookbooks } from '@/sections/PortfolioLookbooks'
import { LookbooksPageQuery } from '@/types/__generated__/LookbooksPageQuery'
import { graphql } from 'gatsby'
import React from 'react'

export const query = graphql`
  query LookbooksPageQuery {
    contentfulPagePortfolioLookbooks {
      title
      metaDescription
    }
  }
`

type Props = {
  data: LookbooksPageQuery
}

const LookbooksPage: React.FC<Props> = ({ data }) => {
  const { title, metaDescription } = data.contentfulPagePortfolioLookbooks

  return (
    <>
      <SEO title={title} metaDescription={metaDescription} />

      <Breadcrumb>
        <BreadcrumbItem href="/">Home</BreadcrumbItem>
        <BreadcrumbItem href="/portfolio/">Portfolio</BreadcrumbItem>
        <BreadcrumbItem>{title}</BreadcrumbItem>
      </Breadcrumb>

      <PortfolioLookbooks id="portfolio-lookbooks" />
    </>
  )
}

export default LookbooksPage
