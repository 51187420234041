import { BrandDot } from '@/components/BrandDot'
import { SectionWrapper } from '@/components/SectionWrapper'
import { StackedCards } from '@/components/StackedCards'
import { useTheme } from '@/store'
import { PortfolioLookbooksQuery } from '@/types/__generated__/PortfolioLookbooksQuery'
import { fluidFontSize, scrimGradient } from '@apostrof/lib'
import { css } from '@emotion/core'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'

type Props = {
  id: string
}

const query = graphql`
  query PortfolioLookbooksQuery {
    contentfulPagePortfolioLookbooks {
      lookbooks {
        name
        slug
        coverImage {
          fluid(
            maxWidth: 1024
            maxHeight: 576
            quality: 85
            resizingBehavior: FILL
          ) {
            ...ContentfulFluid
          }
        }
        sections {
          ... on ContentfulLookbookProject {
            featuredImages {
              __typename
            }
          }
        }
      }
    }
  }
`

export const PortfolioLookbooks: React.FC<Props> = ({ id }) => {
  const theme = useTheme()
  const data = useStaticQuery<PortfolioLookbooksQuery>(query)
  const { lookbooks } = data.contentfulPagePortfolioLookbooks

  return (
    <SectionWrapper id={id}>
      <div>
        <h2
          css={css`
            margin-left: -0.05em;
            color: ${theme.color.text_default};
            ${fluidFontSize(30, 48, 320, theme.vwImageCapWidth)};
            letter-spacing: -0.03em;
          `}
        >
          Lookbooks
          <BrandDot />
        </h2>
        <p
          css={css`
            margin-top: 0.5rem;
            margin-bottom: 2rem;
            color: ${theme.color.text_lighter};
            font-style: italic;
            line-height: 1.25;
          `}
        >
          In a hurry?{' '}
          {lookbooks.length > 1
            ? 'These lookbooks contain '
            : 'The lookbook contains '}
          the best shots of our works.
        </p>
      </div>
      {lookbooks.map(({ name, slug, coverImage, sections }) => {
        const totalImageCountInLookbook = sections.reduce(
          (accumulator, section) => section.featuredImages.length + accumulator,
          0,
        )

        return (
          <article
            key={slug}
            css={css`
              &:not(:first-of-type) {
                margin-top: 6.25%;
              }
            `}
          >
            <Link to={`/portfolio/lookbooks/${slug}/`}>
              <StackedCards>
                <>
                  <Img fluid={coverImage.fluid} alt="" />
                  <div
                    css={css`
                      position: absolute;
                      bottom: 0;
                      left: 0;
                      right: 0;
                      padding: 3.125%;
                      padding-top: 12.5%;

                      ${scrimGradient({
                        color: theme.color.grays[8],
                        direction: 'to top',
                        startAlpha: 0.64,
                      })}
                    `}
                  >
                    <h2
                      css={css`
                        position: relative;
                        margin-bottom: 0.5em;

                        ${fluidFontSize(16, 30, 320, theme.vwImageCapWidth)};
                        color: ${theme.color.grays[0]};
                        text-shadow: ${theme.textShadow};
                      `}
                    >
                      {name}
                    </h2>
                    <p
                      css={css`
                        position: relative;

                        ${fluidFontSize(14, 24, 320, theme.vwImageCapWidth)};
                        color: ${theme.color.grays[2]};
                        text-shadow: ${theme.textShadow};
                      `}
                    >
                      <span
                        css={css`
                          font-weight: 700;
                        `}
                      >
                        {totalImageCountInLookbook}{' '}
                      </span>
                      <span
                        css={css`
                          color: ${theme.color.grays[3]};
                          font-size: 0.6em;
                          text-transform: uppercase;
                          letter-spacing: 0.05em;
                        `}
                      >
                        images from
                      </span>
                      <span
                        css={css`
                          font-weight: 700;
                        `}
                      >
                        {' '}
                        {sections.length}{' '}
                      </span>
                      <span
                        css={css`
                          color: ${theme.color.grays[3]};
                          font-size: 0.6em;
                          text-transform: uppercase;
                          letter-spacing: 0.05em;
                        `}
                      >
                        projects
                      </span>
                    </p>
                  </div>
                </>
              </StackedCards>
            </Link>
          </article>
        )
      })}
    </SectionWrapper>
  )
}
